import { render, staticRenderFns } from "./Partners.vue?vue&type=template&id=586ac8a3&scoped=true&"
import script from "./Partners.vue?vue&type=script&lang=js&"
export * from "./Partners.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586ac8a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VCard,VDivider,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VMain})
